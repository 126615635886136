<template>
  <div>
    <div class="box_one">
      <img class="img_one" src="@/assets/image/page/holdMatch1.png" alt="" />
      <div class="btn_enter">
        <a href="https://manage.sqydh365.com" target="_blank">立即进入办赛端</a>
      </div>
    </div>
    <div>
      <img src="@/assets/image/page/holdMatch2.jpg" class="img_two" />
    </div>
  </div>
</template>

<script>
export default {
  name: "holdMatch",
};
</script>

<style scoped>
.img_one {
  width: 100%;
  height: 4rem;
}
.img_two {
  height: 22.2rem;
  width: 100%;
}
.box_one {
  position: relative;
}
.btn_enter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.6rem;
  width: 2rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #d80c24;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_enter a {
  font-size: 18px;
  color: #fff;
  line-height: 0.5rem;
}
</style>
